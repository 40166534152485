import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUserState } from "redux/types/userTypes";
import { userAPI } from "../api/userApi";
import axios from "axios";
import {BASE_URL} from "../api/commonApi";
import {LoginEvent} from "../../fsd/entities/popup";
import useMediaQuery from "../../hooks/useMediaQuery";

const initialState: IUserState = {
  user: null,
  verification: {
    status: "none",
    message: "",
    rejectReason: "",
  },
};

interface UploadImage {
  obj: FormData;
  onUploadProgress?: (v: any) => void;
}

export const createVerificationImages = createAsyncThunk(
  "adminApi/createVerificationImages",
  async (obj: UploadImage) => {
    return await axios({
      url: `${BASE_URL}/api/verifications`,
      method: "POST",
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: obj.onUploadProgress,
      data: obj.obj,
    })
      .then((pld) => {
        return {
          type: "adminApi/createVerificationImages",
          method: "POST",
          status: pld.status,
        };
      })
      .catch((pld) => {
        throw new Error(`${pld?.response?.data?.message}`);
      });
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetAdmin(state) {
      state.user = null;
    },
    updateBalance(state, { payload }) {
      if (state.user) state.user.balance = payload.balance;
      if (state.user) state.user.bonusBalance = payload.bonusBalance;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVerificationImages.fulfilled, (state: any, action) => {
        state.verification.status = "created";
      })
      .addCase(createVerificationImages.rejected, (state: any, action) => {
        state.verification.status = "declined";
      })
      .addMatcher(
        userAPI.endpoints.loginUser.matchFulfilled,
        (state, { payload }) => {
            window.location.reload()

          state.user = payload;
        }
      )
      .addMatcher(
        userAPI.endpoints.getUserData.matchFulfilled,
        (state, { payload }) => {
            LoginEvent()

          state.user = payload;
        }
      )
      .addMatcher(
        userAPI.endpoints.getUserBalance.matchFulfilled,
        (state, { payload }) => {
          if (state.user) state.user.balance = payload.balance;
        }
      )
      .addMatcher(userAPI.endpoints.deleteUser.matchFulfilled, (state) => {
        state.user = null;
        // clearStorage(["APP_SETTINGS", "API_BASE_URL", "APP_LOADING"])
      })
      .addMatcher(
        userAPI.endpoints.registerUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
        }
      )
      .addMatcher(
        userAPI.endpoints.updateUserData.matchFulfilled,
        (state, { payload }) => {
          // console.log('reducer updateUserData = ', payload)
          state.user = payload;
        }
      )
      .addMatcher(
        userAPI.endpoints.getFullUserData.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
        }
      )
      .addMatcher(
        userAPI.endpoints.createVerification.matchFulfilled,
        (state, { payload }) => {
          state.verification.status = payload.status;
        }
      )
      .addMatcher(
        userAPI.endpoints.getVerificationStatus.matchFulfilled,
        (state, { payload }) => {
          state.verification.status = payload.status || "";
          state.verification.message = payload.message || "";
          state.verification.rejectReason = payload.rejectReason || "";
        }
      )
      .addDefaultCase((state, action) => {});
  },
});

export const { resetAdmin, updateBalance } = userSlice.actions;

export default userSlice.reducer;
