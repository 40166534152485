import {createApi} from "@reduxjs/toolkit/query/react";
import {BaseQueryInterceptor} from "./commonApi";
import {
    ICancelPayment,
    ICancelPaymentRequest, IConvertCurrency,
    IDepositRequest,
    IPayment, IPaymentConvertCurrency, IPaymentSettings, IPaymentSystems,
    IVoucherApply,
    IVoucherApplyRequest,
} from "../types/paymentTypes";


export const paymentApi = createApi({
    reducerPath: 'paymentApi',
    baseQuery: BaseQueryInterceptor,
    endpoints: (build) => ({
        applyVoucher: build.mutation<IVoucherApplyRequest, IVoucherApply>({
            query: (obj: Record<any, any>) => ({
                url: `/vouchers/apply/${obj.voucherCode.toUpperCase()}`,
                method: 'POST'
            }),
        }),
        createPayment: build.mutation<IDepositRequest, IPayment>({
            query: (obj: Record<any, any>) => ({
                url: `/payment`,
                method: 'POST',
                body: obj
            }),
        }),
        convertPaymentCurrency: build.mutation<IConvertCurrency, IPaymentConvertCurrency>({
            query: (obj: Record<any, any>) => ({
                url: `/payment/currency-convert`,
                method: 'GET',
                params: obj
            }),
        }),
        getPaymentSettings: build.mutation<IPaymentSettings, void>({
            query: () => ({
                url: `/payment/settings`,
                method: 'GET'
            }),
        }),
        getPaymentSystems: build.mutation<IPaymentSystems[], void>({
            query: () => ({
                url: `/payment/systems`,
                method: 'GET'
            }),
        }),
        cancelPayment: build.mutation<ICancelPaymentRequest, { id: number, data: ICancelPayment }>({
            query: (id) => ({
                url: `/payment/cancel/${id}`,
                method: 'DELETE'
            }),
        }),
    })
});

export const {
    useApplyVoucherMutation,
    useCreatePaymentMutation,
    useGetPaymentSettingsMutation,
    useGetPaymentSystemsMutation,
    useCancelPaymentMutation,
    useConvertPaymentCurrencyMutation,
} = paymentApi