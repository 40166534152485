import {ISettings} from "./interfaces/ISettings";

type languages = "en" | "tr" | "de" | "sr" | "fr" | "ar";
type Mirrors = 'lettobet1.com' | 'lettobet2.com'

const lettobet_settings: ISettings<languages, Mirrors> = {
    API_DEV_URL: "https://api.lettobet.dev.bet4skill.com",
    API_PROD_URL: "https://api.lettobet.com",

    // https://api.lettobet.dev.bet4skill.com/api-docs/#/Transaction/get_transactions_player_operations

    MIRRORS: {
        'lettobet1.com': {
            API_PROD_URL: 'https://api.lettobet1.com'
        },
        'lettobet2.com': {
            API_PROD_URL: 'https://api.lettobet2.com'
        }
    },

    OTHER: {
        VARIANT: "lettobet",
        CURRENCY: {
            TITLE: "Turkish lira",
            KEY: "TRY",
        },
        LANGUAGES: {
            en: {
                VISIBLE: true,
                DEFAULT: false,
                DIRECTION: "rtl",
                TITLE: "English",
                KEY: "en",
            },
            de: {
                VISIBLE: true,
                DEFAULT: false,
                DIRECTION: "rtl",
                TITLE: "German",
                KEY: "de",
            },
            tr: {
                VISIBLE: true,
                DEFAULT: true,
                DIRECTION: "rtl",
                TITLE: "Turkey",
                KEY: "tr",
            },
            sr: {
                VISIBLE: true,
                DEFAULT: false,
                DIRECTION: "rtl",
                TITLE: "Serbian Bosnian Croatian",
                KEY: "sr",
            },
            fr: {
                VISIBLE: true,
                DEFAULT: false,
                DIRECTION: "rtl",
                TITLE: "French",
                KEY: "fr",
            },
            ar: {
                VISIBLE: true,
                DEFAULT: false,
                DIRECTION: "ltr",
                TITLE: "Arabic",
                KEY: "ar",
            },
        },
        HEADER: [
            "HORSES",
            "SPORT",
            "LIVE",
            "ESPORT",
            "GAMES",
            "TV_GAMES",
            "EVOLUTION",
            "TURBO_GAMES",
            "FAVORITE_GAMES",
            "PROMOTIONS",
            "HELP",
        ],
        LOGIN_LOGO: "lettobet",
        FOOTER: {
            MENU: ["HOME", "SPORT", "LIVE", "GAMES", "TV_GAMES"],
            PAYMENTS: ["maksipara"],
        },
        MOBILE_MENU: {
            HOME: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1,
            },
            SPORT: {
                VISIBLE: true,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1,
            },
            ESPORT: {
                VISIBLE: true,
                ROW: 1,
                COLUMN: 3,
                SPAN: 1,
            },
            LIVE: {
                VISIBLE: true,
                ROW: 1,
                COLUMN: 2,
                SPAN: 1,
            },
            VIRTUAL_GAMES: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 3,
                SPAN: 1,
            },
            GAMES: {
                VISIBLE: true,
                ROW: 2,
                COLUMN: 2,
                SPAN: 1,
            },
            TV_GAMES: {
                VISIBLE: true,
                ROW: 2,
                COLUMN: 1,
                SPAN: 1,
            },
            EVOLUTION_MOBILE: {
                VISIBLE: true,
                ROW: 4,
                COLUMN: 1,
                SPAN: 2,
            },
            AMATIC: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1,
            },
            FAVORITE_GAMES: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1,
            },
            PROMOTIONS: {
                VISIBLE: true,
                ROW: 3,
                COLUMN: 3,
                SPAN: 1,
            },
            HORSES: {
                VISIBLE: true,
                ROW: 5,
                COLUMN: 1,
                SPAN: 1,
            },
            TURBO_GAMES: {
                VISIBLE: true,
                ROW: 2,
                COLUMN: 3,
                SPAN: 1,
            },
            LUDO: {
                VISIBLE: false,
                ROW: 2,
                COLUMN: 3,
                SPAN: 1,
            },
            PRAGMATIC: {
                VISIBLE: true,
                ROW: 3,
                COLUMN: 1,
                SPAN: 2,
            },
            HALL_OF_FAME: {
                VISIBLE: false,
                ROW: 4,
                COLUMN: 3,
                SPAN: 1,
            },
        },
    },
    ACCESS: {
        SPORT: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        BONUSES: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        LIVE_SPORT: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        LOGIN: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        REGISTER: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        RESET_PASSWORD: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        RECOVERY_PASSWORD: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        CONFIRM_EMAIL: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        GAME: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        GAMES: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        TV_GAMES: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        HORSES: {
            VISIBLE: true,
            PERMISSIONS: {
                URACE: true,
                WALLET: false,
            },
        },
        PROFILE: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        PROFILE_PERSONAL_DATA: {
            VISIBLE: true,
            PERMISSIONS: {
                ID: true,
                USERNAME: true,
                PHONE: true,
                EMAIL: true,
                NAME: false,
                SURNAME: false,
                ADDRESS: false,
                ID_CARD: false,
                BALANCE: true,
                CURRENCY: true,
                FIRST_NAME: true,
                SECOND_NAME: true,
                LAST_NAME: true,
                BIRTHDAY: true,
                GET_FULL_DATA: false,
                UPDATE_USER_INFO: false,
            },
        },
        PROFILE_DEPOSIT: {
            VISIBLE: true,
            PERMISSIONS: {
                MAKSI_PAPARA: true,
                MAKSI_CRYPTO: true,
                MAKSI_HAYHAY: false,
                MAKSI_PAYFIX: true,
                MAKSI_CREDIT_CARD: true,
                MAKSI_PEP: true,
                MAKSI_BANK: true,
                VOUCHER: true,
                RUNPAY: false,
                HASHBON: true,
            },
        },
        PROFILE_USER_DOCUMENT: {
            VISIBLE: false,
            PERMISSIONS: {},
        },
        PROFILE_WITHDRAWAL: {
            VISIBLE: true,
            PERMISSIONS: {
                MAKSI_PAPARA: true,
                MAKSI_CRYPTO: true,
                MAKSI_HAYHAY: false,
                MAKSI_PAYFIX: true,
                MAKSI_PEP: true,
                MAKSI_BANK: true,
                BANK: false,
                RUNPAY: false,
                HASHBON: true,
            },
        },
        PROFILE_OPERATION_HISTORY: {
            VISIBLE: true,
            PERMISSIONS: {
                TYPE: true,
                DATE: true,
                AMOUNT: true,
                STATUS: true,
                ID: true,
                DECLAINE_REASON: true,
            },
        },
        PROFILE_BET_HISTORY: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        PROFILE_CASHBACK_HISTORY: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        PROFILE_SETTINGS: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        PROFILE_MESSAGES: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        PROMOTIONS: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        PROMOTION: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
        HALL_OF_FAME: {
            VISIBLE: false,
            PERMISSIONS: {},
        },
    },

    SEO: {
        TITLE: "Lettobet.com",
        SITE_NAME: "LETTOBET",
        LOGO_AND_FAVICON: "lettobet",
        DESCRIPTION: "Play with Lettobet",
        LICENSE:
            "Lettobet.com operates under the License Letto24 B. V., No. 8068/JAZ issued to Antillephone, Authorized and Regulated by the government of Curacao",
        KEYWORDS: ["Lettobet", "lettobet"],
    },

    THEME: {
        VARIANT: "lettobet",
        DEFAULT: "light",
        TOGGLE: false,
    },

    SERVICES_DEV: {
        BETBY: {
            OPERATOR_ID: "2136455562131546112",
            BRAND_ID: "2190074582290014208",
            EXTERNAL_API:
                "https://external-api.invisiblesport.com/api/v1/external_api/",
            BETSLIP_URL: "https://ui.invisiblesport.com/bt-renderer.min.js",
            THEME_NAME: "lettobet",
        },
        TAWK: {
            PROPERTY_ID: "1gfjmgdrb",
            ID: "634dac16b0d6371309ca04aa",
        },
        YM: {
            KEY: "89462512",
        },
    },
    SERVICES_PROD: {
        BETBY: {
            OPERATOR_ID: "2139066602350579712",
            BRAND_ID: "2190166192424099840",
            EXTERNAL_API: "https://gw9yca5f.sptenv.com/api/v1/external_api/",
            BETSLIP_URL: "https://lettobet.sptpub.com/bt-renderer.min.js",
            THEME_NAME: "lettobet",
        },
        TAWK: {
            PROPERTY_ID: "1gfjmgdrb",
            ID: "634dac16b0d6371309ca04aa",
        },
        FB: {
            META_PIXEL: "943869246741782",
            META_CONTENT: "1c5hrd8y7xlukx1be02poagn42ehrf",
        },
        YM: {
            KEY: "91300012",
        },
    },
};

export default lettobet_settings;
