export const formatId = (value: string | number): string => `${value}`.toLocaleLowerCase().replace(/ /g, '_');

export const replaceToNumber = (value: string | number): string => `${value}`.replace(/[^\d]+/g, '');

export const addZero = (value: string | number, length = 2): string => {
    let result = `${value}`;
    for (let i = 0; i < length - `${value}`.length; i++) {
        result = '0' + result;
    }
    return result;
};