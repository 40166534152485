import { useEffect, useState } from 'react';

interface ITimer {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const useTimerCountdown = (endDate: Date | string) => {
    const end = new Date(endDate);

    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;

    const [timer, setTimer] = useState<ITimer>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        const intervalId = setInterval(() => {

            const now = new Date();
            const distance = +end - +now;
            if (distance < 0) {
                clearInterval(intervalId);
                return;
            }

            const days = Math.floor(distance / _day);
            const hours = Math.floor((distance % _day) / _hour);
            const minutes = Math.floor((distance % _hour) / _minute);
            const seconds = Math.floor((distance % _minute) / _second);

            setTimer({ days, hours, minutes, seconds });
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [end]);


    return timer;
};